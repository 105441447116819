import React from 'react';
import ReactDOM from 'react-dom/client';
import '@assets/scss/bootstrap-clover4-wrapper.css';
import '@assets/scss/index.css';
import App from './App';
import {BrowserRouter, HashRouter} from 'react-router-dom';
import ScrollToTop from "@components/ScrollToTop";

const domNode = document.getElementById('clover4');
const reference = domNode.getAttribute('rel') || null;
const basename = domNode.getAttribute('data-basename') || "/";
const landing = domNode.getAttribute('data-landing') || "landing";
const ageGate = (domNode.getAttribute('data-age-gate') || 'true') === 'true';
const root = ReactDOM.createRoot(domNode);
const mode = process.env.REACT_APP_MODE;

if (mode === 'embed') {
    root.render(
        <HashRouter basename={basename}>
            <ScrollToTop/>
            <App slug={reference} landing={landing} ageGate={ageGate} mode={mode}/>
        </HashRouter>
    );
} else if (mode === 'standalone') {
    root.render(
        <BrowserRouter basename={basename}>
            <ScrollToTop/>
            <App slug={reference} mode={mode} ageGate={ageGate}/>
        </BrowserRouter>
    );
} else { //default
    root.render(
        <BrowserRouter>
            <ScrollToTop/>
            <App mode={mode} ageGate={ageGate}/>
        </BrowserRouter>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
