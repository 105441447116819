import {useContext, useEffect} from "react";
import {useParams} from "react-router-dom";
import {CompetitionContext} from "@contexts/CompetitionContext";
import ReactPixel from "react-facebook-pixel";

const useMetaPixel = () => {
    const params = useParams();
    const embedded = (process.env.REACT_APP_MODE !== "default");
    const competition = useContext(CompetitionContext)
    useEffect(() => {
        if (Object.hasOwn(params, 'slug') || embedded === true) {
            if(competition.metadata.analytics.meta?.enabled === true) {
                const pixels = competition.metadata.analytics.meta.pixels;
                pixels.forEach((pixel) => {
                    ReactPixel.init(pixel);
                    ReactPixel.pageView();
                });
            }
        }
    }, [competition, params, embedded]);

};

export default useMetaPixel;