import {useContext, useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import ReactGA from "react-ga4";
import {CompetitionContext} from "@contexts/CompetitionContext";
const useGaTracker = () => {
    const params = useParams();
    const competition = useContext(CompetitionContext)
    const location = useLocation();
    const embedded = (process.env.REACT_APP_MODE !== "default");
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (Object.hasOwn(params, 'slug') || embedded === true) {
            if(competition.metadata.analytics.google?.enabled === true) {
                const measurementId = competition.metadata.analytics.google.measurementId;
                if(measurementId != null) {
                    ReactGA.initialize(measurementId);
                    setInitialized(true);
                }
            }
        } else {
            setInitialized(false);
        }
    }, [competition, params, embedded]);

    useEffect(() => {
        if (initialized) {
            const { slug } = competition;
            const page = location.pathname.replace(`/${slug}/`, '')
            ReactGA.send({ hitType: "pageview", page: page + location.search });
        }
    }, [initialized, location, competition]);
};

export default useGaTracker;